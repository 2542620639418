import React, { useState } from 'react';
import './MatchUpdates.css';
import { FaPlayCircle } from "react-icons/fa";
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdLiveTv } from "react-icons/md";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../header/Header';
import PlaceBet from '../PlaceBet/PlaceBet'; // Import PlaceBet component
import Footer from '../Footer/Footer';
import LoginPopup from '../LoginPopup/LoginPupup';

const MatchUpdates = () => {
  const [activeBet, setActiveBet] = useState(null);
  const [betType, setBetType] = useState(null); // Track the type of bet (back or lay)
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const handlePlaceBackClick = (team) => {
    setActiveBet(activeBet === team ? null : team);
    setBetType('back'); // Set to 'back' for blue background
  };

  const handlePlaceLayClick = (team) => {
    setActiveBet(activeBet === team ? null : team);
    setBetType('lay'); // Set to 'lay' for pink background
  };

  const handleLoginPopupClick = () => {
    setShowLoginPopup(true); // Show the login popup
  };

  const handleLoginPopupClose = () => {
    setShowLoginPopup(false); // Hide the login popup
  };

  return (
    <div>
      <Header />
      <div className="match-update-header">
        <div className="inside-content">
          <FaPlayCircle size={15} color="white" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
          <span>Team A vs Team B</span>
          <p>Ind: 45-0 (5.0 Ovs)</p>
          <p>In play - Team B have won the toss opt to bowl</p>
          <p>commentary: Stumps</p>
        </div>
        <IoInformationCircleOutline size={15} color="white" style={{ marginRight: 0, marginLeft: 150, marginTop: 30 }} />
      </div>
      <div className="header-part-2">
        <a href="#">MARKET</a>
        <a href="#">NEWS</a>
        <a href="#">LIVE <MdLiveTv size={15} color="white" style={{ marginBottom: 5 }} /></a>
        <a href="#">OPEN BETS</a>
      </div>

      <div className="match-status">
        <div className="match-status-heading">
          <span>Match Odds</span>
          <span className='black'>Back</span>
          <span className='lay'>Lay</span>
        </div>
        <div className="match-details">
          <div className="team-name-box">
            <span className='team-name'>Team A</span>
            <div className="team-number">
              {/* <span className='team-back-number' onClick={() => handlePlaceBackClick('TeamA')}>17.5<p>103</p></span> */}
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              {/* <span className='team-lay-number' onClick={() => handlePlaceLayClick('TeamA')}>17.5<p>103</p></span> */}
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
          <div className="team-name-box">
            <span className='team-name'>Team B</span>
            <div className="team-number">
              {/* <span className='team-back-number' onClick={() => handlePlaceBackClick('TeamB')}>17.5<p>103</p></span> */}
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              {/* <span className='team-lay-number' onClick={() => handlePlaceLayClick('TeamB')}>17.5<p>103</p></span> */}
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
          <div className="team-name-box">
            <span className='team-name'>The Draw</span>
            <div className="team-number">
              {/* <span className='team-back-number' onClick={() => handlePlaceBackClick('Draw')}>17.5<p>103</p></span> */}
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              {/* <span className='team-lay-number' onClick={() => handlePlaceLayClick('Draw')}>17.5<p>103</p></span> */}
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
        </div>
      </div>

      {activeBet && (
        <div className="dropdown-container">
          <PlaceBet betType={betType} /> {/* Pass betType as a prop */}
        </div>
      )}

      <div className="match-status">
        <div className="match-status-heading-toss">
          <span>To Win The Toss</span>
        </div>
        <div className="match-details">
          <div className="team-toss-box">
            <span className='team-toss A'>Team A</span>
            <span className='team-toss B'>Team B</span>
          </div>
          <div className="toss-box-number">
          <div className="toss-box" onClick={handleLoginPopupClick}><span>98</span></div>
          <div className="toss-box" onClick={handleLoginPopupClick}><span>98</span></div>
          </div>
        </div>
      </div>
      <div className="match-status">
        <div className="match-status-heading">
          <span>Bookmaker</span>
          <span className='black'>Black</span>
          <span className='lay'>Lay</span>
        </div>
        <div className="match-details">
          <div className="team-name-box">
            <span className='team-name'>Team A</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
          <div className="team-name-box">
            <span className='team-name'>Team B</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
          <div className="team-name-box">
            <span className='team-name'>The Draw</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
        </div>
      </div>
      <div className="premium-cricket">
        <div className='cricket-premium'><span>Premium Cricket</span></div>
      </div>
      <div className="match-status">
        <div className="match-status-heading">
          <span>Will be draw</span>
          <span className='black'>Black</span>
          <span className='lay'>Lay</span>
        </div>
        <div className="match-details">
          <div className="team-name-box">
            <span className='team-name'>Yes</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
          <div className="team-name-box">
            <span className='team-name'>No</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
        </div>
      </div>
      <div className="match-status">
        <div className="match-status-heading">
          <span>Over 0-10 TeamA Total</span>
          <span className='black1'>Black</span>
          <span className='lay'>Lay</span>
        </div>
        <div className="match-details">
          <div className="team-name-box">
            <span className='team-name'>Yes</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
          <div className="team-name-box">
            <span className='team-name'>No</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
        </div>
      </div>
      <div className="match-status">
        <div className="match-status-heading">
          <span>Over 2 wicktes</span>
          <span className='black1'>Black</span>
          <span className='lay'>Lay</span>
        </div>
        <div className="match-details">
          <div className="team-name-box">
            <span className='team-name'>Over 2</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
          <div className="team-name-box">
            <span className='team-name'>Under 2</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
        </div>
      </div>
      <div className="match-status">
        <div className="match-status-heading">
          <span>1st innings TeamA Total</span>
          <span className='black1'>Black</span>
          <span className='lay'>Lay</span>
        </div>
        <div className="match-details">
          <div className="team-name-box">
            <span className='team-name'>Over 17.5</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
          <div className="team-name-box">
            <span className='team-name'>Under17.5</span>
            <div className="team-number">
              <span className='team-back-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
              <span className='team-lay-number' onClick={handleLoginPopupClick}>17.5<p>103</p></span>
            </div>
          </div>
        </div>
      </div>
      {showLoginPopup && <LoginPopup onClose={handleLoginPopupClose} />}
      <Footer/>
    </div>
  );
}

export default MatchUpdates;
