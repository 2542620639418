import React from 'react';
import './ScrollGames.css';
const ScrollGames = () => {
  return (
    <div className="scrollable-box">
      <div className="inner-box">
        <button className='box-button'>Cricket</button>
        <button className='box-button-1'>Football</button>
        <button className='box-button-2'>Live Casino</button>
        <button className='box-button-3'>Tennis</button>
        <button className='box-button-4'>Horse Racing</button>
        <button className='box-button-5'>Evolution</button>
        <button className='box-button-6'>Slot Games</button>
        <button className='box-button-7'>Fortune Spin</button>
      </div>
    </div>
  );
}

export default ScrollGames;