import React, { useState } from 'react';
import './SignUp.css'; // Import the CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoMdCloseCircle } from "react-icons/io";
import { GiCombinationLock } from "react-icons/gi";
import { FaMobileAlt, FaCaretDown, FaUser, FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons

const SignUp = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle password visibility

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle between showing and hiding password
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle between showing and hiding password
  };

  return (
    <>
      {/* Login Button */}
      {/* <button type="button" className="btn btn-warning" onClick={togglePopup}>Sign Up</button> */}
      <button className="pushable-signup submit-btn login-trigger " onClick={togglePopup}>
        <span className="front-signup">Sign Up</span> 
      </button>

      {/* Popup */}
      {showPopup && (
        <div className="login-popup-overlay" onClick={togglePopup}>
          <div className="login-popup-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn">
            <IoMdCloseCircle onClick={togglePopup} />
            </span>
            <img className='form-logo' src="../logo.png" alt="" />
            <form>
              <div className="phnumber-signup">
                <span className='country-code'>+91</span>
              <input type="number" name="username" placeholder="Enter Your 10 Digit Number" className='phnumber' required />
              <button className='getotp'>Get OTP</button>
              </div>
              <input type="number" name="number" placeholder="Enter OTP" className='otp-input' required />

              <div className="signup-password">
                <GiCombinationLock size={30} color="#e9b02e" style={{ marginRight: 0, marginTop: 5, marginLeft: 5 }}/>
                <input
                  className='password-input'
                  type={showPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                  name="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span className="password-toggle-signup" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                </span> {/* Eye icon to toggle password visibility */}
              </div>
              <div className="signup-password">
                <GiCombinationLock size={30} color="#e9b02e" style={{ marginRight: 0, marginTop: 5, marginLeft: 5 }}/>
                <input
                  className='password-input'
                  type={showConfirmPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                  name="confirm-password"
                  placeholder="Confirm Password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span className="confirm-password-toggle-signup" onClick={toggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                </span> {/* Eye icon to toggle password visibility */}
              </div>
                
              <input type="text" name="referral" placeholder="Referral Code (if any)" />
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                <label class="form-check-label" for="flexCheckDefault">
                  Remember me
                </label>
              </div>
              <button className="pushable submit-btn">
                <span className="front">
                  Register
                </span>
              </button>
              <span className='other-login'>Or register with</span>
            <span className="other-button">
              <button className='google'><img src="./google.png" alt="" className='google-image'/>Google</button>
              <button className='whatsapp'><img src="./whatsapp.png" alt="" className='whatsapp-image' />WhatsApp</button>
            </span>
            <span className='new-account'>Already have an account? Login</span> {/* Added onClick event */}
            </form>
          </div>
        </div>
      )}
    </>
  );
}; 

export default SignUp;
