import React, {useState} from 'react';
import './GamesImages.css';

import LoginPopup from '../LoginPopup/LoginPupup';

const GamesImages = () => {

  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const handleLoginPopupClick = () => {
    setShowLoginPopup(true); // Show the login popup
  };

  const handleLoginPopupClose = () => {
    setShowLoginPopup(false); // Hide the login popup
  };

  return (
    <div>
      <div className="scrollable-box-image">
        <div className="inner-box-image">
          <button className='box-images' onClick={handleLoginPopupClick}>
              <img className='box-image' src="../box 1.png" alt="" />
          </button>
          <button className='box-images' onClick={handleLoginPopupClick}>
              <img className='box-image' src="../box 2.png" alt="" />
          </button>
          <button className='box-images' onClick={handleLoginPopupClick}>
              <img className='box-image' src="../box 3.png" alt="" />
          </button>
          <button className='box-images' onClick={handleLoginPopupClick}>
              <img className='box-image' src="../box 4.png" alt="" height="180px" />
          </button>
          <button className='box-images' onClick={handleLoginPopupClick}>
              <img className='box-image' src="../box 5.png" alt="" />
          </button>
          <button className='box-images' onClick={handleLoginPopupClick}>
              <img className='box-image' src="../box 6.png" alt="" />
          </button>
          <button className='box-images' onClick={handleLoginPopupClick}>
              <img className='box-image' src="../box 7.png" alt="" />
          </button>
        </div>
        {showLoginPopup && <LoginPopup onClose={handleLoginPopupClose} />}
      </div>
    </div>
  );
}

export default GamesImages;
