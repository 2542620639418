import React from 'react';
import './ExposureHeader.css';
import { HiCurrencyRupee } from "react-icons/hi";

const ExposureHeader = () => {
  return (
    <div>
      <div className="main-header">
      <HiCurrencyRupee size={20} color=" #FFD700" style={{ marginRight: 5, marginLeft: 100, marginTop: 1 }} /><span className='main-balance'>Main: <p>0.00</p></span>
      <span className='exposure-balance'>Exposure: <p>0.00</p></span>
      </div>
    </div>
  );
}

export default ExposureHeader;
