import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import MainScreen from './components/MainScreen/MainScreen';
import MatchUpdates from './components/MatchUpdates/MatchUpdates';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import AccountStatus from './components/AccountStatus/AccountStatus';
import PlaceBet from './components/PlaceBet/PlaceBet';
import AfterLogin from './components/AfterLogin/AfterLogin';
import { useSelector } from 'react-redux';
import AccountStatement from './components/AccountStatement/AccountStatement';

// const App = () => {
  // const isAuthenticated = localStorage.getItem('isAuthenticated'); // Retrieve auth status from localStorage

 function App() {
  // const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp />} />
          
          {/* Protected Routes */}
          {/* <Route
            path="/ "
            element={isAuthenticated ? <MainScreen /> : <Navigate to="/login" />}
          />
          <Route
            path="/matchupdates"
            element={isAuthenticated ? <MatchUpdates /> : <Navigate to="/login" />}
          />
          <Route
            path="/accountstatus"
            element={isAuthenticated ? <AccountStatus /> : <Navigate to="/login" />}
          />
          <Route
            path="/placebet"
            element={<PlaceBet />}
          /> */}
          {/* <Route path='/' element={ <AfterLogin/> }/> */}
          <Route path='/' element={ <MainScreen/> } />
          <Route path='/matchupdates' element={ <MatchUpdates/> } />
          <Route path='/accountstatus' element={ <AccountStatus/> } />
          <Route path='/placebet' element={ <PlaceBet/> } />
          <Route path='/accountstatement' element={ <AccountStatement/> } />
        </Routes>
    </Router>
  );
 }
// };

export default App;
