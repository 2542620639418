import React, { useState } from 'react';
// import Login from './Login'; // Import the Login component
// import SignUp from './SignUp'; // Import the SignUp component
import Login from '../Login/Login';
import SignUp from '../SignUp/SignUp';

const AuthPopup = () => {
  const [showLogin, setShowLogin] = useState(false);  // State to show Login popup
  const [showSignUp, setShowSignUp] = useState(false);  // State to show SignUp popup

  const toggleLoginPopup = () => {
    setShowLogin(!showLogin);
    setShowSignUp(false);  // Ensure SignUp is closed when Login is open
  };

  const toggleSignUpPopup = () => {
    setShowSignUp(!showSignUp);
    setShowLogin(false);  // Ensure Login is closed when SignUp is open
  };

  return (
    <>
      {/* Render Login and SignUp Buttons */}
      <button onClick={toggleLoginPopup}>Login</button>
      <button onClick={toggleSignUpPopup}>Sign Up</button>

      {/* Pass the toggle functions to the Login and SignUp components */}
      {/* {showLogin && <Login toggleSignUp={toggleSignUpPopup} />} */}
      {showLogin && <Login toggleSignUp={toggleSignUpPopup} />}
      {/* {showSignUp && <SignUp toggleLogin={toggleLoginPopup} />} */}
      {showLogin && <SignUp toggleLogin={toggleLoginPopup} />}

    </>
  );
};

export default AuthPopup;
