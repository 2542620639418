import React from 'react'
import "./AccountStatement.css";
import Header from '../header/Header';
import Footer from '../Footer/Footer';
import ExposureHeader from '../ExposureHeader/ExposureHeader';

const AccountStatement
 = () => {
  return (
    <>
      <Header/>
      <ExposureHeader/>
      <div className='account-statment'>
        <ul className='account-back'>
            <li className='ac-statement'><strong>Account Statment</strong></li>
            <li className='back-statement'><strong>Back</strong></li>
        </ul>

    <div className='all-buttons'>
        <button className='account-statement-button'>All</button>
        <button className='account-statement-button'>D & W</button>
        <button className='account-statement-button'>Casino Profit</button>
        <button className='account-statement-button'>Settelment</button>
        <button className='account-statement-button'>Match Profit</button>
        <button className='account-statement-button'>Ledger</button>
    </div>
    <div className='form'>
        <form>
            <input type='datetime-local' />
            <input type='datetime-local' />
            <input type='search' placeholder='Search' />
            <input type='submit' className='btn-submit'/>
            <input type='reset'className='btn-submit'/>
        </form>
    </div>

    <div className="table-container">
            
      <table className="scrollable-table">
        <thead>
          <tr><th>Sr.no</th>
            <th>Date</th>
            <th>Description</th>
            <th>Credit</th>
            <th>Debit</th>
            <th>Commission</th>
            <th>Match P & L</th>
            <th>Balance</th>
            
          </tr>
        </thead>
        <tbody>
          {/* Sample data for the table */}
          <tr>
            <td>1</td>
            <td>2024-09-15 13:52:48</td>
            <td><a href='' className='table-match' >INDIA A v INDIA D/ Fancy S Samson run 2</a></td>
            <td className='credit'>100.00</td>
            <td className='debit'>0.00</td>
            <td>0.00</td>
            <td>100.00</td>
            <td>9,600.00</td>
          </tr>
          <tr>
            <td>1</td>
            <td>2024-09-15 13:52:48</td>
            <td><a href='' className='table-match' >INDIA A v INDIA D/ Fancy S Samson run 2</a></td>
            <td className='credit'>100.00</td>
            <td className='debit'>0.00</td>
            <td>0.00</td>
            <td>100.00</td>
            <td>9,600.00</td>
          </tr>
          <tr>
            <td>1</td>
            <td>2024-09-15 13:52:48</td>
            <td><a href='' className='table-match' >INDIA A v INDIA D/ Fancy S Samson run 2</a></td>
            <td className='credit'>100.00</td>
            <td className='debit'>0.00</td>
            <td>0.00</td>
            <td>100.00</td>
            <td>9,600.00</td>
          </tr>
       
         
          {/* Add more rows as needed */}
        </tbody>
        

      </table>
      
      
    </div>

    </div>
    <Footer/>
  </>
  )
}

export default AccountStatement

