import React, { useState } from 'react';
import Hero from '../Hero/Hero';
import Main from '../Main/Main';
import ScrollGames from '../ScrollGames/ScrollGames';
import GamesImages from '../GamesImages/GamesImages';
import UpcomingEvents from '../UpcomingEvents/UpcomingEvents';
import MainFooter from '../MainFooter/MainFooter';
import Footer from '../Footer/Footer';
import Header from '../header/Header';
import AuthPopup from '../AuthPopup/AuthPopup';
import ExposureHeader from '../ExposureHeader/ExposureHeader';
import Offcanvas from 'react-bootstrap/Offcanvas';

const MainScreen = () => {
  // State to control the header visibility
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  return (
    <div>
      {/* Ensure header is always visible and buttons inside it */}
      <Header />
      {/* <ExposureHeader/> */}
      <Hero text="SignUp now and get 50% bonus on your first deposit." speed={30}/>
      <Main/>
      <GamesImages/>
      <ScrollGames/>
      <UpcomingEvents/>
      <MainFooter/>

      {/* AuthPopup should overlay on top and not interfere with other components */}
      <AuthPopup show={isOffcanvasOpen} onHide={() => setIsOffcanvasOpen(false)} />

      {/* Footer contains the button that controls offcanvas */}
      <Footer setIsOffcanvasOpen={setIsOffcanvasOpen} />
    </div>
  );
}

export default MainScreen;