import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import the CSS file
import { IoMdCloseCircle } from "react-icons/io";
import { FaMobileAlt, FaCaretDown, FaUser, FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons

const Login = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for toggling dropdown
  const [inputType, setInputType] = useState('mobile'); // State for controlling which input type is selected
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();  // Use navigate hook from react-router-dom

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleInputTypeChange = (type) => {
    setInputType(type);
    setDropdownOpen(false); // Close dropdown after selection
    setUsername(''); // Reset username field when switching input types
  };

  // const handleLogin = (e) => {
  //   e.preventDefault();

  //   // Hardcoded credentials for testing
  //   const hardcodedUsername = 'user';
  //   const hardcodedPassword = '1234';

  //   // Check the credentials
  //   if (username === hardcodedUsername && password === hardcodedPassword) {
  //     // Store login status in localStorage
  //     localStorage.setItem('isAuthenticated', 'true');
  //     setError(''); // Clear any previous errors

  //     // Navigate to the main page after successful login
  //     navigate('/');  // Redirect to home page (or whichever route you prefer)
  //   } else {
  //     setError('Invalid username or password');  // Display an error message
  //   }
  // };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle between showing and hiding password
  };

  const handleCreateAccount = () => {
    navigate('/signup'); // Navigate to the signup page
  };

  return (
    <>
      {/* Login Button */}
      <button className="pushable-login submit-btn login-trigger " onClick={togglePopup}>
        <span className="front-login">Login</span> 
      </button>

      {/* Popup */}
      {showPopup && (
        <div className="login-popup-overlay" onClick={togglePopup}>
          <div className="login-popup-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn">
              <IoMdCloseCircle onClick={togglePopup} />
            </span>
            <img className='form-logo' src="../logo.png" alt="" />
            <form>
              {/* Dynamic label */}
              <label className="input-label-above">
                {inputType === 'mobile' ? 'Mobile Number' : 'User ID'}
              </label>
              
              {/* Username Section with Dropdown */}
              <div className="username">
                <span className="input-label">
                  {inputType === 'mobile' ? '+91' : 'ID'}
                </span>
                
                <input
                  className='login-input'
                  type="text"
                  name="username"
                  placeholder={inputType === 'mobile' ? 'Enter mobile number' : 'Enter user ID'}
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />

                <span className="icon" onClick={toggleDropdown}>
                  <FaMobileAlt size={30} color="#e9b02e" style={{ marginRight: 0 }} /> <FaCaretDown size={20} color="#e9b02e" style={{ marginRight: 0 }} />
                </span> {/* Mobile icon */}

                {/* Dropdown Menu */}
                {dropdownOpen && (
                  <div className="dropdown-menu show">
                    <div onClick={() => handleInputTypeChange('mobile')}><FaMobileAlt size={20} color="#e9b02e" style={{ marginRight: 0 }} />Mobile Number</div>
                    <div onClick={() => handleInputTypeChange('userid')}><FaUser size={20} color="#e9b02e" style={{ marginRight: 0 }} />User ID</div>
                  </div>
                )}
              </div>
              
              <span className="password-label">
                 Password
              </span>
              <div className="password">
                <input
                  className='password-input'
                  type={showPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                  name="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span className="password-toggle" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                </span> {/* Eye icon to toggle password visibility */}
              </div>
              
              <button className="pushable submit-btn">
                <span className="front">
                  Login
                </span>
              </button>
            </form>
            {error && <p className="error">{error}</p>}
            <span className='other-login'>Or login with</span>
            <span className="other-button">
              <button className='google'><img src="./google.png" alt="" className='google-image'/>Google</button>
              <button className='whatsapp'><img src="./whatsapp.png" alt="" className='whatsapp-image' />WhatsApp</button>
            </span>
            <span className='new-account' onClick={handleCreateAccount}>New User? Create Account</span> {/* Added onClick event */}
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
