import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import './PlaceBet.css';

const PlaceBet = ({ betType }) => {
  const [stake, setStake] = useState('');
  const [maxMarket, setMaxMarket] = useState(0);
  const navigate = useNavigate(); // Initialize useNavigate
  
  // This assumes 'isAuthenticated' is a string 'true' or 'false' in localStorage
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true'; // Proper boolean check

  // Update stake when maxMarket is selected
  const handleMaxMarketChange = (newMaxMarket) => {
    setMaxMarket(newMaxMarket);
    setStake(newMaxMarket); // Auto-fill the input with the selected maxMarket value
  };

  const handleStakeChange = (event) => {
    const newStake = event.target.value;
    setStake(newStake); // Update stake when input changes
  };

  const handlePlaceBet = () => {
    if (!isAuthenticated) {
      // If the user is not authenticated, redirect to the login page
      navigate('/login');
    } else {
      // Implement your bet placement logic here
      console.log('Placing bet with stake:', stake);
    }
  };

  return (
    <div className={`place-bet ${betType === 'back' ? 'blue-bg' : 'pink-bg'}`}>
      <div className="place-bet-header">
        <span className='span1'>ODDS</span>
        <span className='span2'>Stake</span>
        <span className='span3'>Max Market: {maxMarket}</span>
      </div>
      <div className="bet-place-row">
        <div className="bet-place-number">
          <span>10</span>
        </div>
        <div className="bet-place-amount">
          {/* Auto-fill input when maxMarket is selected */}
          <input
            type="number"
            value={stake}
            onChange={handleStakeChange}
            placeholder={`Max: ${maxMarket}`}
            className='bet-place-amount'
          />
        </div>
      </div>
      <div className="bet-placing-amount-row">
        {/* When clicked, set the input value to the selected amount */}
        <div className="box1" onClick={() => handleMaxMarketChange(200)}>+200</div>
        <div className="box1" onClick={() => handleMaxMarketChange(500)}>+500</div>
        <div className="box1" onClick={() => handleMaxMarketChange(1500)}>+1500</div>
      </div>
      <div className="bet-placing-row-actions">
        <button className="button-35-cancel" role="button">
          <span className="text">Cancel</span>
        </button>
        <button className="button-35" role="button" onClick={handlePlaceBet}>
          <span className="text">Place Bet</span>
        </button>
      </div>
    </div>
  );
};

export default PlaceBet;
