import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PiSealWarningBold } from "react-icons/pi";
import { BiSolidCricketBall, BiFootball } from "react-icons/bi";
import './UpcomingEvents.css';

const UpcomingEvents = () => {
  const [currentTime, setCurrentTime] = useState(new Date('2024-08-26T04:30'));
  const scrollRefs = useRef([]);

  const navigate = useNavigate();

  const initializeScrollRefs = (index) => {
    if (!scrollRefs.current[index]) {
      scrollRefs.current[index] = React.createRef();
    }
  };

  const handleScroll = (scrollingRef) => {
    scrollRefs.current.forEach(ref => {
      if (ref.current && ref !== scrollingRef) {
        ref.current.scrollLeft = scrollingRef.current.scrollLeft;
      }
    });
  };

  // Move handleMatchClick here, outside of useEffect
  const handleMatchClick = () => {
    navigate('/matchupdates'); // Navigate to MatchUpdates
  };

  useEffect(() => {
    scrollRefs.current.forEach((ref) => {
      if (ref.current) {
        ref.current.addEventListener('scroll', () => handleScroll(ref));
      }
    });

    return () => {
      scrollRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.removeEventListener('scroll', () => handleScroll(ref));
        }
      });
    };
  }, []);

  return (
    <div className='main'>
      <ul className='live'>
        <li>LIVE</li>
        <li>My Bets(0)</li>
      </ul>

      {/* Cricket Section */}
      <div className="cricket">
        <div className="header-box">
          <BiSolidCricketBall size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
          <span>Cricket</span>
        </div>
        <div className="bet-numbers">
          <div className="box"><span>1</span></div>
          <div className="box"><span>X</span></div>
          <div className="box"><span>2</span></div>
        </div>
      </div>

      {/* Event Boxes */}
      {[...Array(2)].map((_, i) => {
        initializeScrollRefs(i);

        return (
          <div className="event-box" key={i}>
            <div className="date-time-box">
              <p>{i % 2 === 0 ? 'Tomorrow' : currentTime.toLocaleDateString()}</p>
              <span className="date">{currentTime.toLocaleTimeString()}</span>
            </div>
            <div className="matches" onClick={handleMatchClick}>
              <div className='a'>Match {i + 1}</div>
              <p>Team B</p>
            </div>
            <div
              className="event-numbers"
              ref={scrollRefs.current[i]}
              onScroll={() => handleScroll(scrollRefs.current[i])}
            >
              <div className="box"><span>2.5 <p>4</p></span></div>
              <div className="box"><span>-</span></div>
              <div className="box"><span>1.66 <p>7</p></span></div>
              <div className="box-pink"><span>2.52 <p>14</p></span></div>
              <div className="box-pink"><span>-</span></div>
              <div className="box-pink"><span>1.67 <p>16</p></span></div>
            </div>
          </div>
        );
      })}

      {/* Football Section */}
      <div className="cricket">
        <div className="header-box">
          <BiFootball size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
          <span>Football</span>
        </div>
        <div className="bet-numbers">
          <div className="box"><span>1</span></div>
          <div className="box"><span>X</span></div>
          <div className="box"><span>2</span></div>
        </div>
      </div>

      {/* Event Boxes */}
      {[...Array(6)].map((_, i) => {
        initializeScrollRefs(i + 6);

        return (
          <div className="event-box" key={i + 6}>
            <div className="date-time-box">
              <p>{i % 2 === 0 ? 'Tomorrow' : currentTime.toLocaleDateString()}</p>
              <span className="date">{currentTime.toLocaleTimeString()}</span>
            </div>
            <div className="matches">
              <div className='a'>Match {i + 1}</div>
              <p>Team B</p>
            </div>
            <div
              className="event-numbers"
              ref={scrollRefs.current[i + 6]}
              onScroll={() => handleScroll(scrollRefs.current[i + 6])}
            >
              <div className="box"><span>3.9<p>148</p></span></div>
              <div className="box"><span>3.55</span><p>16.5</p></div>
              <div className="box"><span>2.16<p>10</p></span></div>
              <div className="box-pink"><span>3.95<p>20</p></span></div>
              <div className="box-pink"><span>3.6</span><p>128</p></div>
              <div className="box-pink"><span>2.18<p>3.232</p></span></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UpcomingEvents;
