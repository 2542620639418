import React, { useState } from 'react';
import './Footer.css';
import { BiCricketBall, BiGift, BiHome, BiWallet } from "react-icons/bi";
import { BsFilePerson } from 'react-icons/bs';
import { Offcanvas } from 'react-bootstrap';
import AccountStatus from '../AccountStatus/AccountStatus';
import { useNavigate } from 'react-router-dom';
import LoginPopup from '../LoginPopup/LoginPupup';

const Footer = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const navigate = useNavigate();

  const handlePersonIconClick = () => {
    setShowOffcanvas(true);
  };

  const handleClose = () => {
    setShowOffcanvas(false);
  };

  const handleClick = () => {
    navigate('/');
  };

  const handleCricketBallClick = () => {
    setShowLoginPopup(true); // Show the login popup
  };

  const handleLoginPopupClose = () => {
    setShowLoginPopup(false); // Hide the login popup
  };

  return (
    <>
      <nav className="navbar-footer navbar-expand-lg bg-dark">
        <div className="container-fluid">
          <div className='icons'>
            {/* Trigger login popup on click */}
            <button className='footer-button' onClick={handleCricketBallClick}>
              <BiCricketBall size={30} color="white" style={{ marginRight: 10 }} />
            </button>
            
            <button className='footer-button'  onClick={handleCricketBallClick}>
              <BiGift size={30} color="white" style={{ marginRight: 10 }} />
            </button>
            <button className='footer-button' onClick={handleClick}>
              <BiHome size={30} color="white" style={{ marginRight: 10 }} />
            </button>
            <button className='footer-button'  onClick={handleCricketBallClick}>
              <BiWallet size={30} color="white" style={{ marginRight: 10 }} />
            </button>
            <button className='footer-button' onClick={handlePersonIconClick} style={{ cursor: 'pointer' }}>
              <BsFilePerson size={30} color="white" style={{ marginRight: 10 }} />
            </button>
          </div>
        </div>
      </nav>

      {/* Direct Login Form Popup */}
      {showLoginPopup && <LoginPopup onClose={handleLoginPopupClose} />}

      {/* Fullscreen Offcanvas for Account Status */}
      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end" className="offcanvas-fullscreen">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Account Status</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AccountStatus handleClose={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Footer;
