import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AccountStatus.css';
import { TbCoin } from "react-icons/tb";
import { MdOutlineReplay, MdOutlineSettings } from "react-icons/md";
import { FaRegCircleUser } from "react-icons/fa6";
import { LuLogOut } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import { IoReorderThree } from "react-icons/io5";
import { GrCurrency } from "react-icons/gr";
import { RiPagesLine } from "react-icons/ri";
import { FaBook, FaLock } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import LoginPopup from '../LoginPopup/LoginPupup';
import AccountStatement from '../AccountStatement/AccountStatement';

const AccountStatus = ({ handleClose }) => {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showAccountStatement, setShowAccountStatement] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear authentication data
    localStorage.removeItem('isAuthenticated'); // Clear the authentication flag
    localStorage.removeItem('userToken'); // Clear any other user data if needed

    handleClose(); // Close the Offcanvas (if this is relevant in your app)

    // Redirect to login page
    navigate('/login');
  };

  const handleLoginPopupClick = () => {
    setShowLoginPopup(true); // Show the login popup
  };

  const handleLoginPopupClose = () => {
    setShowLoginPopup(false); // Hide the login popup
  };

  const handleAccountStatement = () =>{
    setShowAccountStatement(true);
    navigate('/accountstatement');
  }

  return (
    <div className='main-section'>
      <div className="section-second">
        <nav className="navbar-account">
          <button type="button" className="btn btn-dark bet">
            <TbCoin size={25} color="white" style={{ marginRight: 0 }} />Bets
          </button>
          <button type="button" className="btn btn-dark replay">
            <MdOutlineReplay size={25} color="white" style={{ marginRight: 0 }} />
          </button>
          <button type="button" className="btn btn-dark setting">
            <MdOutlineSettings size={25} color="white" style={{ marginRight: 0 }} />
          </button>
        </nav>
        {/* <nav className="navbar-small">
          <span className='demo'>
            <FaRegCircleUser size={25} color="black" style={{ marginRight: 10 }} />
            demo_user
          </span>
        </nav> */}
        <div className="vertical-navbar">
          <ul>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><IoReorderThree size={25} color="black" style={{ marginRight: 10 }} /> Open Bets</button></li>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><GrCurrency size={25} color="black" style={{ marginRight: 10 }} />Betting Profit And Loss</button></li>
            <li><button className='ac-button' onClick={handleAccountStatement}><RiPagesLine size={25} color="black" style={{ marginRight: 10 }} />Account Statement</button></li>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><FaBook size={25} color="black" style={{ marginRight: 10 }} />Transfer Statement</button></li>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><FaLock size={25} color="black" style={{ marginRight: 10 }} />Change Password</button></li>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><IoMdSettings size={25} color="black" style={{ marginRight: 10 }} />Settings</button></li>
          </ul>
          {/* <ul>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><IoReorderThree size={25} color="black" style={{ marginRight: 10 }} /> Open Bets</button></li>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><GrCurrency size={25} color="black" style={{ marginRight: 10 }} />Betting Profit And Loss</button></li>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><RiPagesLine size={25} color="black" style={{ marginRight: 10 }} />Account Statement</button></li>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><FaBook size={25} color="black" style={{ marginRight: 10 }} />Transfer Statement</button></li>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><FaLock size={25} color="black" style={{ marginRight: 10 }} />Change Password</button></li>
            <li><button className='ac-button' onClick={handleLoginPopupClick}><IoMdSettings size={25} color="black" style={{ marginRight: 10 }} />Settings</button></li>
          </ul> */}
        </div>
        {showLoginPopup && <LoginPopup onClose={handleLoginPopupClose} />}
        {/* Logout button */}
        <button type="button" onClick={handleLoginPopupClick} className="btn btn-danger account">
          Login <LuLogOut size={25} color="white" style={{ marginLeft: 10 }} />
        </button>
      </div>
    </div>
  );
};

export default AccountStatus;
