import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Offcanvas, Button } from 'react-bootstrap';
import { FaDice, FaHorse, FaHorseHead, FaRegEye, FaRocket, FaTrophy, } from "react-icons/fa";
import { BiCricketBall, BiFootball, BiSolidTennisBall } from "react-icons/bi";
import { ImSpades } from 'react-icons/im';
import { PiMonitorPlay } from 'react-icons/pi';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import Login from '../Login/Login';
import SignUp from '../SignUp/SignUp';
import LoginPopup from '../LoginPopup/LoginPupup';

const Header = ({  onLoginClick }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLoginPopupClick = () => {
    setShowLoginPopup(true); // Show the login popup
  };

  const handleLoginPopupClose = () => {
    setShowLoginPopup(false); // Hide the login popup
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" fixed="top" className="sticky-header">
        <div className="d-flex align-items-center">
          {/* Hamburger Menu Button on the Left Side */}
          <Button
            className={`navbar-toggler me-2 hamburger ${showMenu ? 'open' : ''}`}
            type="button"
            onClick={handleToggleMenu}
            aria-controls="navbarSupportedContent"
            aria-expanded={showMenu}
            aria-label="Toggle navigation" 
            variant="dark">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </Button>
          <NavLink className="navbar-brand" to="/"><img className='header-logo'  src="../logo.png" alt=''/></NavLink>
          <SignUp/>
          <Login  onClick={onLoginClick}/>
        </div>
      </Navbar>

      {/* Offcanvas menu for mobile */}
      <Offcanvas show={showMenu} onHide={handleToggleMenu} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
             <ul className="navbar-nav mr-auto">
             <li className="nav-item active">
               <button className="nav-link" onClick={handleLoginPopupClick} ><FaRegEye size={30} color="black" style={{ marginRight: 10 }}   />My markets</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleLoginPopupClick}><FaRocket size={30} color="Orange" style={{ marginRight: 10 }}  />Blastoff</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleLoginPopupClick}><BiCricketBall size={30} color="#900C3F" style={{ marginRight: 10 }}  />Cricket</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleLoginPopupClick}><BiFootball size={30} color="black" style={{ marginRight: 10 }}  />Football</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleLoginPopupClick}><BiSolidTennisBall size={30} color="green" style={{ marginRight: 10 }}  />Tennis</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleLoginPopupClick}><FaHorseHead size={30} color="black" style={{ marginRight: 10 }}  />Horce Racing</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleLoginPopupClick}><FaHorse size={30} color="brown" style={{ marginRight: 10 }}  />Grayhound Racing</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleLoginPopupClick}><ImSpades size={30} color="black" style={{ marginRight: 10 }}  />Indian Card Games</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleLoginPopupClick}><FaTrophy size={30} color="#ffd133" style={{ marginRight: 10 }}  />Sportsbook (80+)</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleLoginPopupClick}><PiMonitorPlay size={30} color="black" style={{ marginRight: 10 }}  />Casino</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleLoginPopupClick}><FaDice size={30} color="red" style={{ marginRight: 10 }}  />Slot Games</button>
             </li>
           </ul>
          </Nav>
          {showLoginPopup && <LoginPopup onClose={handleLoginPopupClose} />}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
