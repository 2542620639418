import React from 'react';
import './MainFooter.css';

function MainFooter() {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="gaming-png">
         <img src="./terms & Condition.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default MainFooter;